import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch, Redirect } from 'react-router-dom'
import useToggle from '@rooks/use-toggle'

import routeConfig from 'routeConfig'
import Layout from 'components/Layout/Layout'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'
import config from 'config'

const Posts = loadable(() =>
  import(/* webpackChunkName: "Posts" */ '../Posts/Posts')
)
const Category = loadable(() =>
  import(/* webpackChunkName: "Category" */ '../Category/Category')
)
const Search = loadable(() =>
  import(/* webpackChunkName: "Search" */ '../Search/Search')
)
const Post = loadable(() =>
  import(/* webpackChunkName: "Post" */ '../Post/Post')
)
const Page = loadable(() =>
  import(/* webpackChunkName: "Page" */ '../Page/Page')
)

const menu = [
  {
    to: routeConfig.posts,
    label: 'Nyeste filmer'
  },
  {
    to: routeConfig.traningTypes,
    label: 'Treningsformer'
  },
  {
    to: routeConfig.courses,
    label: 'Kurs'
  },
  {
    to: routeConfig.search,
    label: 'Søk'
  }
]

const App = () => {
  const [sidebarVisible, toggleSidebar] = useToggle(false)
  const menuProps = {
    menu,
    sidebarVisible,
    toggleSidebar
  }
  return (
    <Layout
      header={config.showUi ? <AppHeader {...menuProps} /> : null}
      sidebar={<AppSidebar {...menuProps} />}
    >
      <Switch>
        <Redirect from={routeConfig.home} to={routeConfig.posts} exact />
        <Route
          path={`${routeConfig.search}/:phrase?/:after?`}
          component={Search}
        />
        <Route path={`${routeConfig.posts}/:after?`} component={Posts} />
        <Route
          path={`${routeConfig.category}/:slug/:after?`}
          component={Category}
        />
        <Route path={`${routeConfig.post}/:id?`} component={Post} />
        <Route path={'/:slug'} component={Page} />
      </Switch>
    </Layout>
  )
}

export default App
