import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Header from 'components/Layout/Header'

let currentPath = null

const AppHeader = ({
  menu,
  history: { replace },
  location: { pathname },
  sidebarVisible,
  toggleSidebar
}) => {
  useEffect(() => {
    if (pathname !== currentPath && sidebarVisible) {
      toggleSidebar()
    }
    currentPath = pathname
  })
  return (
    <Header
      menu={menu}
      sidebarVisible={sidebarVisible}
      toggleSidebar={toggleSidebar}
    />
  )
}

AppHeader.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  menu: PropTypes.array
}

export default withRouter(AppHeader)
