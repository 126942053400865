import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useOutsideClick from '@rooks/use-outside-click'

const widthMobile = '20rem'
const Container = styled.aside`
  background: ${p => p.theme.colors.primary};
  transition-duration: 300ms;
  backface-visibility: hidden;
  z-index: 5001;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0px;
  left: -${widthMobile};
  width: ${widthMobile};
  right: auto;
  height: 100%;
  max-height: 100%;
  ${props =>
    props['data-visible']
      ? css`
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          transform: translate(${widthMobile}, 0px);
        `
      : 'visibility: hidden;'}
`

const OffCanvas = ({ visible, onToggleVisible, children, ...props }) => {
  const elementRef = useRef()
  const outsidePClick = useCallback(() => {
    visible && onToggleVisible && onToggleVisible()
  }, [visible, onToggleVisible])
  useOutsideClick(elementRef, outsidePClick)
  useEffect(() => {
    window.document.body.style.overflow = visible ? 'hidden' : ''
    return () => {
      window.document.body.style.overflow = ''
    }
  })
  return (
    <Container data-visible={visible} ref={elementRef} {...props}>
      {children}
    </Container>
  )
}

OffCanvas.propTypes = {
  visible: PropTypes.bool,
  onToggleVisible: PropTypes.func,
  children: PropTypes.any
}

export default OffCanvas
