import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import config from 'config'

const Container = styled(Link)`
  display: block;
  text-align: center;
  font-size: 2rem;
  font-style: italic;
  font-weight: bold;
`

const Logo = ({ fontSize = 2, mini }) => {
  return <Container to="/">{config.name}</Container>
}

Logo.propTypes = {
  fontSize: PropTypes.number
}

export default Logo
