import { theme } from '@smooth-ui/core-sc'

const colors = {
  primary: '#f04766',
  muted: '#567',
  text: '#444',
  linkInverted: '#00b9eb',
  link: '#0073aa',
  transparent: 'rgba(0,0,0,0)',
  gray1: '#e5e5e5',
  gray2: '#ddd',
  gray3: '#aaa',
  gray4: '#666',
  gray5: '#32373c',
  gray6: '#23282d'
}

export default {
  ...theme,
  ...colors,
  info: colors.gray1,
  light: 'rgba(255,255,255,0.2)',
  colors,
  shadow: '0 0 6px rgba(0,0,0,.3)',
  borderRadius: '.25rem',
  sizes: {
    xxxs: 0.8,
    xxs: 1.0,
    xs: 1.2,
    sm: 1.4,
    md: 1.6,
    xl: 1.8,
    xxl: 2,
    xxxl: 2.4
  }
}
