import React from 'react'
import { render } from 'react-dom'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

import Root from 'Root'
import config from 'config'

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(config.token)
  const authorizationHeader = token ? `Bearer ${token}` : null
  operation.setContext({
    headers: {
      authorization: authorizationHeader
    }
  })
  return forward(operation)
})

const client = new ApolloClient({
  link: ApolloLink.from([
    middlewareAuthLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: '/graphql',
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache()
})

const dest = document.getElementById('root')

render(<Root client={client} />, dest)
