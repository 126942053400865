import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Route, Link } from 'react-router-dom'
import { Box, Button } from '@smooth-ui/core-sc'

import OffCanvas from '../OffCanvas/OffCanvas'
import Logo from './Logo'

const SidebarButton = styled(Button)`
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const NavLink = ({ label, to, activeOnlyWhenExact }) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <SidebarButton
        as={Link}
        to={to}
        variant={match ? 'light' : 'transparent'}
      >
        {label}
      </SidebarButton>
    )}
  />
)

const Sidebar = ({ sidebarVisible, toggleSidebar, menu }) => {
  return (
    <OffCanvas visible={sidebarVisible} onToggleVisible={toggleSidebar}>
      <Box p={2}>
        <Box mb={2} color="white">
          <Logo />
        </Box>
        {menu &&
          menu.map((item, i) => {
            return <NavLink key={i} {...item} />
          })}
      </Box>
    </OffCanvas>
  )
}

Sidebar.propTypes = {
  sidebarVisible: PropTypes.bool
}

export default Sidebar
