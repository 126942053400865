import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { hot } from 'react-hot-loader'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'

import App from 'containers/App/App'
import theme from 'helpers/theme'
import bodyStyle from 'helpers/bodyStyle'

const GlobalStyle = createGlobalStyle`${bodyStyle}`

const Root = ({ client }) => (
  <HelmetProvider>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </HelmetProvider>
)

Root.propTypes = {
  client: PropTypes.object
}

export default hot(module)(Root)
