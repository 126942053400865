import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  background: white;
  color: ${p => p.theme.colors.text};
  display: flex;
  width: 100%;
`
const Main = styled.main`
  flex: 1;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const Content = styled.main`
  flex: 1;
  padding: 1rem;
  ${p =>
    p.centerContent &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`
const Footer = styled.footer`
  text-align: center;
  padding: 1rem;
  color: ${p => p.theme.colors.muted};
  font-size: 0.8rem;
`

const Layout = ({ children, centerContent, sidebar, header }) => (
  <Container>
    {sidebar}
    <Main>
      {header}
      <Content centerContent={centerContent}>{children}</Content>
      <Footer>Webtrening © 2019</Footer>
    </Main>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  header: PropTypes.any,
  centerContent: PropTypes.bool
}

export default Layout
